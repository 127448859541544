import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84')
];

export const server_loads = [0];

export const dictionary = {
		"/": [23],
		"/auth": [78],
		"/auth/verify-email": [79],
		"/chrome-extension": [80],
		"/error": [81],
		"/healthcheck/diagnose": [82],
		"/(login)/login": [24,[2]],
		"/(login)/sign-up": [25,[2]],
		"/(login)/sign-up/hp-verify": [26,[2]],
		"/(user)/user-home": [27,[3]],
		"/(user)/user-profile": [28,[3,4]],
		"/(user)/user-profile/account": [29,[3,4]],
		"/(user)/user-profile/appearance": [30,[3,4]],
		"/(user)/user-profile/overview": [31,[3,4]],
		"/(user)/user-profile/profile": [32,[3,4]],
		"/(user)/verify-email": [33,[3]],
		"/zendesk_app": [83,[20]],
		"/zendesk_app/[community=slug]/copilot": [84,[20,21,22]],
		"/[community=slug]": [34,[5],[6]],
		"/[community=slug]/ai-history": [39,[5,8],[6]],
		"/[community=slug]/ai-history/[slug]": [40,[5,8],[6]],
		"/[community=slug]/beta-sandbox": [41,[5,9],[6]],
		"/[community=slug]/(knowledge-base)/category/[slug]/[uuid]": [35,[5,7],[6]],
		"/[community=slug]/changelog": [42,[5,10],[6]],
		"/[community=slug]/changelog/[slug]/[uuid]": [43,[5,10],[6]],
		"/[community=slug]/community/forum/[...rest]": [45,[5],[6]],
		"/[community=slug]/community/[...rest]": [44,[5],[6]],
		"/[community=slug]/content-library": [46,[5,11],[6]],
		"/[community=slug]/context-sources": [47,[5],[6]],
		"/[community=slug]/demo": [48,[5,12],[6]],
		"/[community=slug]/(knowledge-base)/doc": [36,[5,7],[6]],
		"/[community=slug]/(knowledge-base)/doc/[slug]/[uuid]": [37,[5,7],[6]],
		"/[community=slug]/forums": [49,[5,13],[6]],
		"/[community=slug]/forums/community-error": [52,[5,13],[6]],
		"/[community=slug]/forums/home": [53,[5,13],[6]],
		"/[community=slug]/forums/members": [54,[5,13],[6]],
		"/[community=slug]/forums/page/[slug]/[uuid]": [55,[5,13],[6]],
		"/[community=slug]/forums/[forumSlug]/[uuid]": [50,[5,13,14],[6]],
		"/[community=slug]/forums/[forumSlug]/[uuid]/[threadSlug]/[threadUuid]": [51,[5,13,14],[6]],
		"/[community=slug]/home": [56,[5],[6]],
		"/[community=slug]/insights": [57,[5,15],[6]],
		"/[community=slug]/insights/knowledge-base": [~58,[5,15],[6]],
		"/[community=slug]/insights/workspace": [59,[5,15],[6]],
		"/[community=slug]/join": [60,[16]],
		"/[community=slug]/join/[inviteCode]": [61,[16]],
		"/[community=slug]/(knowledge-base)/section/[slug]/[uuid]": [38,[5,7],[6]],
		"/[community=slug]/settings": [62,[5,17],[6]],
		"/[community=slug]/settings/changelog/settings": [63,[5,17],[6]],
		"/[community=slug]/settings/community/custom-roles": [64,[5,17],[6]],
		"/[community=slug]/settings/community/labels": [65,[5,17],[6]],
		"/[community=slug]/settings/community/manage-members": [66,[5,17],[6]],
		"/[community=slug]/settings/community/settings": [67,[5,17],[6]],
		"/[community=slug]/settings/general/overview": [68,[5,17],[6]],
		"/[community=slug]/settings/general/team": [69,[5,17],[6]],
		"/[community=slug]/settings/general/webhooks": [70,[5,17],[6]],
		"/[community=slug]/settings/help-center/content-links": [71,[5,17],[6]],
		"/[community=slug]/settings/help-center/custom-domain": [72,[5,17],[6]],
		"/[community=slug]/settings/help-center/settings": [73,[5,17],[6]],
		"/[community=slug]/settings/widget/integrations": [74,[5,17],[6]],
		"/[community=slug]/settings/widget/settings": [75,[5,17],[6]],
		"/[community=slug]/widget": [76,[18]],
		"/[community=slug]/workspace": [77,[5],[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';