import { error, type Reroute } from "@sveltejs/kit";
import { getSubdomainInfo } from "./utils/urls";

// get all the top-level [community] routes based on the +page/+server files
const files = import.meta.glob(
  "/src/routes/[community=slug]/**/{+page.ts,+page.svelte,+server.ts}"
);
const communityRoutes = new Set(
  // remove everything except the top-level route/folder name
  Object.keys(files).map(
    (file) =>
      file
        .replace("/src/routes/[community=slug]/", "")
        .replace(/^\(.*\)\//, "") // remove any top-level groups (e.g. (knowledge-base)/)
        .split("/")[0]
  )
);

/** Reroute all "community" routes to have a community slug param.
 *
 * Custom domain routes don't have a community slug param, so we add it here,
 * this makes accessing the community param much easier in the rest of the app.
 */
export const reroute: Reroute = ({ url }) => {
  const { communitySlug, usingSubdomain, pathPrefix } = getSubdomainInfo(url.hostname);

  // route as normal for non-subdomains
  if (!usingSubdomain) {
    return;
  }

  let pathParts = url.pathname.split("/").slice(1);
  // get root path ("community" in /community/threads, "doc" in /doc/foo/bar/, etc)
  let topLevelRoute = pathParts[0];

  // support custom path prefixes
  if (pathPrefix) {
    if (topLevelRoute !== pathPrefix) {
      throw Error("pathPrefix does not match url pathname");
    }
    //remove path prefix
    pathParts = pathParts.slice(1);
    topLevelRoute = pathParts[0];
  }

  // reroute all [community] urls to include slug param
  if (!topLevelRoute || communityRoutes.has(topLevelRoute)) {
    const subdir = pathPrefix ? `${pathPrefix}/` : "";
    const dst = `/${subdir}${communitySlug}/${pathParts.join("/")}`;
    console.log(`Reroute: ${url.pathname}->${dst}`);
    return dst;
  }

  // subdomain communities should not allow routes of the form:
  // <slug>/<communityRoute>/...
  if (topLevelRoute && topLevelRoute === communitySlug) {
    error(404, "Subdomain communities should not have a community slug in the route.");
  }

  // route as normal for any non-community pages (auth, login, etc)
  return;
};
